import React, { useRef, useEffect, useState } from 'react';
import { ErrorHandler } from '../../../common/error';
import './ApprEditorPage.css'
import { CircularProgress } from '@mui/material';

// 전자결재 에디터 작성 화면
// 작성자 최성우
function ApprEditorPage() {
  const autoFocus = true; //자동 포커스 여부
  const errorHandler = new ErrorHandler(); //에러 핸들러
  const queryParams = new URLSearchParams(window.location.search);
  const fullsize = queryParams.get('fullsize'); //읽기모드인지 여부 
  const typeCode = queryParams.get('typeCode'); //문서 종류 템플릿 번호
  const editorRef = useRef();

  //최초 로딩
  useEffect(() => {

    if (!queryParams.has('fullsize')) {
      errorHandler.logError('fullsize 파라미터는 필수 키 입니다.');
      errorHandler.goToErrorPage();
      return;
    }
    //에디터 초기화
    initEditor();
  }, []);


  // 키보드 내려감을 감지
  useEffect(() => {
    const handleResize = () => {
      const isKeyboardVisible = window.visualViewport && window.visualViewport.height < window.innerHeight;
      const body = document.querySelector('.mce-content-body ');

      // alert(isKeyboardVisible)
      if (!isKeyboardVisible) {
        try {
          postAppMessage('getEditorContent');
        } catch (e) {
          console.error(e.message);
        }
      } 
    };

    const handleScroll = (event) => {
      // if (window.visualViewport && window.visualViewport.height < window.innerHeight) {
      //   event.preventDefault();
      //   window.scrollTo(0, 0);
      // }
    };

    window.visualViewport.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
  }, []);



  const initEditor = () => {
    // if(!window.tinymce) {
    //   return false;
    // }
    window.tinymce.init({
      selector: '#editor',
      height: getMaxHeight(),
      language: 'ko_KR',
      allow_mathml_annotation_encodings: [ 'wiris', 'application/x-tex' ],
      forced_root_block : false,
      force_br_newlines : true,
      force_p_newlines : false,
      forced_root_block : false,
      // forced_root_block : 'br',
      setup : function (ed) {
        ed.on('click', function (evt) {
          postAppMessage('editor_focus');
        });
        ed.on('init', function (evt) {
          if (typeCode === '10') {
            ed.setContent(
              `<table style="width:956px; margin-left: auto; margin-right: auto; height: 132px;" border="1" >
								<tbody>
								<tr style="height: 22px;">
								<td style="width: 5.1242%; height: 22px; text-align: center; background-color: #e5e5e5;"><strong>NO</strong></td>
								<td style="width: 16.6666%; text-align: center; background-color: #e5e5e5; height: 22px;"><strong>거래처</strong></td>
								<td style="width: 28.3754%; text-align: center; background-color: #e5e5e5; height: 22px;"><strong>적요</strong></td>
								<td style="width: 12.9504%; text-align: center; background-color: #e5e5e5; height: 22px;"><strong>금액</strong></td>
								<td style="width: 21.4934%; height: 22px; text-align: center; background-color: #e5e5e5;"><strong>계좌정보</strong></td>
								<td style="width: 15.3901%; height: 22px; text-align: center; background-color: #e5e5e5;"><strong>비고</strong></td>
								</tr>
								<tr style="height: 22px;">
								<td style="width: 5.1242%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 16.6666%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 28.3754%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 12.9504%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 21.4934%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 15.3901%; height: 22px; text-align: center;">&nbsp;</td>
								</tr>
								<tr style="height: 22px;">
								<td style="width: 5.1242%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 16.6666%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 28.3754%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 12.9504%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 21.4934%; height: 22px; text-align: center;">&nbsp;<123/td>
								<td style="width: 15.3901%; height: 22px; text-align: center;">&nbsp;</td>
								</tr>
								<tr style="height: 22px;">
								<td style="width: 5.1242%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 16.6666%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 28.3754%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 12.9504%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 21.4934%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 15.3901%; height: 22px; text-align: center;">&nbsp;</td>
								</tr>
								<tr style="height: 22px;">
								<td style="width: 5.1242%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 16.6666%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 28.3754%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 12.9504%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 21.4934%; height: 22px; text-align: center;">&nbsp;</td>
								<td style="width: 15.3901%; height: 22px; text-align: center;">&nbsp;</td>
								</tr>
								<tr style="height: 22px;">
								<td style="width: 50.1662%; height: 22px; text-align: center; background-color: #e5e5e5;" colspan="3"><strong>합계</strong></td>
								<td style="width: 12.9504%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 21.4934%; text-align: center; height: 22px;">&nbsp;</td>
								<td style="width: 15.3901%; text-align: center; height: 22px;">&nbsp;</td>
								</tr>
								</tbody>
							</table>`
            );
          }
        });
      },
      plugins: [
        'advlist', 'table', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
        'insertdatetime', 'media', 'table', 'help', 'wordcount'
      ],
      toolbar: 'blocks | ' +
      'fontsize bold table forecolor italic backcolor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | ',
      content_style: 'body { font-family:GmarketSansTTFMedium,Helvetica,Arial,sans-serif; font-size:16px }'
    }).then(()=> {
      if (document.querySelector('.tox-statusbar')) {
        document.querySelector('.tox-statusbar').style.display = 'none';
      }


      const textEditArea = document.querySelector('.tox-edit-area iframe');
      const innerDoc = textEditArea.contentDocument || textEditArea.contentWindow.document;
      const element1 = innerDoc.createElement('style');
      element1.innerHTML = 'p { line-height: 15px !important; }';
      innerDoc.head.appendChild(element1);


      const fontSizeElement = document.querySelector('[data-mce-name="fontsize"]');
      if (fontSizeElement) {
        fontSizeElement.style.width = '70px';
      }


      const toolbarFontStyle = document.createElement('style');
      toolbarFontStyle.innerHTML = `
        .tox .tox-toolbar--scrolling .tox-toolbar__group {
          font-family: 'GmarketSansTTFMedium' !important;
        }
      `;
      document.head.appendChild(toolbarFontStyle);

      const editorContainerStyle = document.createElement('style');
      editorContainerStyle.innerHTML = `
        .tox-tinymce {
          border-radius: 0px !important;
          border : none !important;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
          overflow: hidden;
          position: relative;
          visibility: inherit !important;
        }
      `;
      document.head.appendChild(editorContainerStyle);

      const styleElement = document.createElement('style');
      styleElement.innerHTML = '.tox .tox-edit-area::before { border: none !important; }';
      document.head.appendChild(styleElement);

      const editorHeaderStyle = document.createElement('style');
      editorHeaderStyle.innerHTML = `
        .tox:not(.tox-tinymce-inline) .tox-editor-header {
           background-color: unset !important;
           //border-bottom: unset !important;
          box-shadow: unset !important;
            border-bottom: 1px solid #ECECEC !important;
           padding: unset !important;
        }
      `;
      document.head.appendChild(editorHeaderStyle);

      
      //메뉴 박스가 모바일화면에서 작아지는거 강제로 처리;
      //옵저버로 에디터가 init 되는 순간부터 tox-tinymce-aux class 를 detect해서 처리
      //에디터 자체 이벤트 리스너 제공 함수를 찾지못해서 ... 일단 이렇게 처리함; 24.12.09 최성우

      const observer = new MutationObserver((mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            const targetNode = document.querySelector('.tox-form__grid--2col');
            if (targetNode) {
              const groups = targetNode.querySelectorAll('.tox-form__group');
              if (groups.length > 3) {
                  groups[2].remove();
              // groups[3].remove();
              }
            }
            if (targetNode) {
              // targetNode가 생겼을 때 처리할 코드
              console.log('targetNode가 생성되었습니다.',targetNode);
            }
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });


      //테이블 행,열,셀 삭제
      const observer_table = new MutationObserver((mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
        const collectionGroups = document.querySelectorAll('.tox-collection__group');
        collectionGroups.forEach(group => {
          const items = group.querySelectorAll('[aria-label="셀"], [aria-label="행"], [aria-label="열"]');
          items.forEach(item => item.remove());
        });
          }
        }
      });

      observer_table.observe(document.body, { childList: true, subtree: true });


      //강제 옵저버 종료
      receiveAppMessage();

      //에디터 로딩 완료시 앱으로 메시지 전달
      setTimeout(() => {
        postAppMessage('editor_loaded');
        document.querySelector('#loadingOverlay').style.display = 'none';
      }, 100)
    });
  };

  //에디터 최대 높이
  const getMaxHeight = () => {

    const isKeyboardVisible = window.visualViewport && window.visualViewport.height < window.innerHeight;
    if (isKeyboardVisible) {
      return window.visualViewport.height - 200;
    }
    return Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  };

  // 뷰포트 고정 함수
  const fixViewport = () => {
    document.documentElement.style.width = '100%';
    document.body.style.width = '100%';
  };


  // 에디터가 초기화가 완료됐을때 앱에서 오는 메시지를 수신해서 에디터 컨텐츠를 동적으로 변환함.
  const receiveAppMessage = () => {

    const messageHandler = (event) => {
      try {
        if (!event?.data) {
          return;
        }
        const receive = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
        //단 첫번째 작성시에는 고정된 템플릿을 보여주기 위해 체크
        if (receive.key === 'atw_docConents') {
            if(receive.data.content.length > 0) {
              window.tinymce.activeEditor.setContent(receive.data.content);
            } else {
              window.tinymce.activeEditor.setContent('');
            }
        }
        if(receive.key === 'call_open_full_size_editor') {
            postAppMessage('open_full_size_editor');
        }
      } catch (e) {
        errorHandler.logError(e.message);
      }
    };

    window.addEventListener('message', messageHandler);
    document.addEventListener('message', messageHandler); // For Android
    fixViewport();
    return () => {
      window.removeEventListener('message', messageHandler);
      document.removeEventListener('message', messageHandler); // For Android
    };

  }

  //앱 쪽으로 메시지 전달
  const postAppMessage = (messageType) => {
    let content = '';

    if(!window.ReactNativeWebView) {
      return false;
    }

    switch (messageType) {
      case 'send_editor_content': //현재 사용자가 작성한 에디터 내용을 앱으로 전달
        content = window.tinymce.activeEditor.getContent();
        window.ReactNativeWebView.postMessage(JSON.stringify({
          key: messageType,
          desc: '현재 사용자가 작성한 에디터 내용을 앱으로 전달합니다.',
          data: {
            content: content
          }
        }));
        break;
      case 'getEditorContent': //현재 사용자가 작성한 에디터 내용을 앱으로 전달
        content = window.tinymce.activeEditor.getContent();

        window.ReactNativeWebView.postMessage(JSON.stringify({
          key: messageType,
          desc: '현재 사용자가 작성한 에디터 내용을 앱으로 전달합니다.',
          data: {
            content: content
          }
        }));
        break;
      case 'open_full_size_editor': //현재 사용자가 작성한 에디터 내용을 앱으로 전달
        content = window.tinymce.activeEditor.getContent();

        window.ReactNativeWebView.postMessage(JSON.stringify({
          key: messageType,
          desc: '현재 사용자가 작성한 에디터 내용을 앱으로 전달하고 전체화면으로 전환합니다.',
          data: {
            content: content
          }
        }));
        break;
      case 'editor_focus': //isRead 모드일때 에디터 포커싱이 감지시 앱으로 포커싱 감지 메시지 전달
        content = window.tinymce.activeEditor.getContent();
        window.ReactNativeWebView.postMessage(JSON.stringify({
          key: messageType,
          desc: 'isRead 모드일때 에디터 포커싱이 감지시 앱으로 포커싱 감지 메시지 전달',
          data: {
            content: content
          }
        }));
        break;
      case 'editor_loaded': //editor 로딩 완료
        window.ReactNativeWebView.postMessage(JSON.stringify({
          key: messageType,
          desc: '에디터 init이 완료되었을때 앱으로 전달되는 메시지',
          data: {
            content: content
          }
        }));
        break;
    }
  }

  return (
    <>
      <div id="loadingOverlay" style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        display: '' // 초기에는 숨김
      }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </div>
      </div>

      <div className="container" style={{ display: 'flex', flexDirection: 'column', position: 'relative', overflow: 'hidden', background: '#FFF' }}>
        {fullsize === 'Y' ?
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 18,
            borderBottomWidth: 1,
            borderBottomColor: '#ECECEC',
            backgroundColor: '#EFF1F7',
            padding: '8px 20px',
            borderTopWidth: 1,
            borderTopColor: '#ECECEC'
          }}>
            <span style={{ fontWeight: 500, fontSize: 15, color: '#70768D' }}>
              상세내용 <span style={{ fontWeight: 500, fontSize: 16, color: '#FF5EA4' }}> * </span>
            </span>
            <div style={{ display: 'flex', gap: 8, justifyContent: 'center', alignItems: 'center' }}>
              <img src="/images/arrow_btn_gray.png" />
              <span style={{ fontWeight: 400, fontSize: 12 }} onClick={() => postAppMessage('send_editor_content')}>
                접기
              </span>
            </div>
          </div> : <></>}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden', backgroundColor: '#FFF', borderTop: '1px solid #EEE' }}>
          <textarea id="editor" ref={editorRef} style={{ fontFamily: 'GmarketSansTTFMedium',  flex: 1, height: 'auto', overflow: 'auto', backgroundColor: '#FFF', paddingBottom: 50 }} />
        </div>
      </div>
    </>
  );
}

export default ApprEditorPage;
